body {
  .od-search{
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .oppprint-menu{
    margin-right: 128px;
    &.collapsed{
      margin-right: 20px;
    }
    @media screen and(max-width: 767px){
      margin-right: 5px;
    }
  }
  .oppprint-site-select{
    @media screen and(max-width: 767px){
      min-width: 160px !important;
    }
  }
  .oppprint-site-select{
    @media screen and(max-width: 400px){
      min-width: 140px !important;
    }
  }
    @media screen and(max-width: 540px){
      .ant-calendar{
        width: 100%;
        font-size: 12px;
      }
      .ant-calendar-range{
        width: 100%;
        max-width: 100%;
      }
    }
    @media screen and(max-width: 376px){
      .filter-and-date-range{
        .range-picker{
          width: 221px !important;
        }
      }
    }

  .ant-tabs-nav .ant-tabs-tab-active{
    color: #ff9100;
  }
  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date{
    background: #ff9100;
  }
  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover{
    background: #ff9100;
  }
  .ant-calendar-today .ant-calendar-date{
    color: #ff9100;
    font-weight: bold;
    border-color: #ff9100;
  }
  .ant-btn-link {
    color: #ff9100;
  }
  .ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    padding: 21px;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
  }

  .ant-table-bordered .ant-table-tbody>tr>td {
    border-right: 1px solid #e8e8e8;
    padding: 10px;

    label {
      font-weight: normal;
    }
  }

  .ant-btn.ant-btn-primary{
    background-color: #ff9100;
    border-color: #ff9100;
  }

  a{
    color: #ffc000;
    &:hover{
      color: #ff9100 !important;
    }
  }
  .ant-drawer-header {
    padding: 19px;
  }
  .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
    color: #ff9100;
  } 
  .ant-menu-item > a:hover {
    color: #ff9100;
  }
  .ant-menu-submenu-title:hover {
    color: #ff9100;
  }

  .ant-menu-submenu-arrow:hover{
    color: #ff9100;
  }

  .ant-calendar-date:hover {
    background: rgb(253, 234, 193);
  }

  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #ff9100;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ff9100 !important;
    opacity: 1.1 !important;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #ff9100;
  }
  .ant-select-selection:hover{
    border-color: #ff9100;
  }
  .ant-select-selection:before{
    border-color: #ff9100;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    color:#ff9100;
  }
  .ant-pagination-item-active {
    border-color: #ff9100;
  }
  .ant-pagination-item-active a {
    color: #ff9100;
  }
  .ant-pagination-item-active:hover {
    border-color: #ff9100;
  }
  .ant-pagination-item-active:hover a {
    color: #ff9100;
  }
  .ant-spin-dot-item {
    background-color: #ff9100;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff9100;
    border-color: #ff9100;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #ff9100;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover{
    color: #ff9100;
  }
  

  body .ant-select-selection:hover{
    border-color: #ff9100;
  }

  .ant-pagination-item-active:focus, .ant-pagination-item-active:hover{
    border-color: #ff9100;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #ff9100;
  }

  .ant-input:hover {
    border-color: #ff9100;
  }

  .ant-input:focus {
    
  }
  .ant-input:focus {
    border-color: #ff9100;
    border-right-width:none;
    outline: none;
    -webkit-box-shadow:none; 
    box-shadow: none;
  }
  .hblYke .anticon.pod-check {
    background-color: #ff9100;
  }
  .ant-dropdown-menu-item a span {
    color: #ff9100 !important;
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #ff9100;
  }

  .ant-btn:hover, .ant-btn:focus{
    color: #ff9100;
    border-color: #ff9100;
  }

  .ant-btn-primary{
    color: #ffffff;
  }

  .ant-btn-primary:focus, .ant-btn-primary:hover{
    color: #ffffff;
  }

  .od-search .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child{
    border-radius: 0;
  }

  .search-field .ant-select-selection--single{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  
  .ant-select-selection:hover{
    border-color: #ff9100;
  }

  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled){
    border-color: #ff9100;
  }

  .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a{
    color: #ff9100;
  }
  
  .ant-pagination-item-active a{
    color: #ff9100;
  }

  .ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: #ff9100;
  }

  .ant-pagination-item-active{
    border-color: #ffc000;
  }

  .ant-tabs-ink-bar{
    background-color: #ff9100;
  }

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-active:hover, .ant-menu-submenu-title:hover {
    color: #ff9100;
  }

  .ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus{
    color: #ff9100;
    border-color: #ff9100;
  }
  
  .ant-menu-submenu-selected {
    color: #ff9100;
  }

  .ant-calendar-date:active {
    background: #ff9100;
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before{
    background: #fbdc9a;
  }

  .ant-btn-background-ghost.ant-btn-primary{
    color: #ff9100;
    border-color: #ff9100;
  }
  
  .ant-pagination-item-active a{
    color: #ff9100;
  }
  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
      border-color: #ff9100;
      border-right-width: none;
       outline: none; 
       -webkit-box-shadow: none;
      box-shadow: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: none;
    background: none;
    border-color:none;
     -webkit-box-shadow: none;
     box-shadow:none;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff9100;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #ff9100;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: transparent;
    border-radius: 4px;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
    background: #ff9100;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
    background: #ff9100;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #ff9100; 
  }
  .ant-input-group-addon:hover {
    border: 1px solid #ff9100;
    cursor: pointer;
    span {
      color: #ff9100;
  }
  }
  
  @media only screen and (max-width: 768px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
      width: 100% !important;
    }
    // .ant-calendar-date-panel{
    //   display: grid
    // }
    // .ant-calendar-body {
    //   width: 280px;
    // }
    // .ant-calendar {
    //   width: 280px
    // }
  }

  .detail-order {
    line-height: 3;

    .order-item {
      margin: 30px 0px;
      border: 1px solid rgb(219, 210, 210);
      border-width: 1px 0 1px;

      .ant-card-bordered {
        border: unset;
      }

      .titel-item {
        margin: 0px 12px;
      }

      .list-item {
        border-top: 1px solid rgb(219, 210, 210);

        .image img {
          width: 70px;
          margin: 10px 0px;
          justify-content: center;
          border: 1px solid #f5f5f5;
          text-align: center;
          border-radius: 5px;
          height: auto;
        }

        .title-sku {
          color: #ccc;
          margin: 10px 0px;
        }
      }
    }
  }

  .attribute {
    .ant-table-tbody {
      box-sizing: unset;
      transition: unset;

      .ant-table-row-level-0 {
        transition: unset;
        box-sizing: unset;

        .ant-table-row-cell-break-word {
          transition: unset;

          .editable-cell-value-wrap {
            text-align: left;
          }
        }
      }
    }

  }
}

.ant-table-content {
  .ant-table-body {
    background: #FFF;
    border: 1px solid #e8e8e8;
    border-bottom: none;

    tr td {
      padding: 10px 15px;

      p {
        margin: 0;
      }
    }
  }

  .ant-table-thead {
    tr th {
      color: #ff9100;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.ant-layout-footer {
  padding: 15px;
  text-align: center;
}

.ant-card-head {
  min-height: 40px;
  padding: 0 15px;

  .ant-card-head-title {
    padding: 10px 0;
  }
}

.ant-card-body {
  padding: 15px;

  .ant-form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-form-item-label {
  font-weight: 500;
  line-height: 25px;
}

.ant-form-item-required {
  &:before {
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ant-form-horizontal {
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.product-type-modal {
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 0;

    .ant-table-content .ant-table-body tr td {
      padding: 5px 10px;
    }
  }

  .ant-modal-footer {
    border-top: none;
  }
}

.ant-table-content .ant-table-body {
  border: 0 none;
}

.ant-table-tbody {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.product-variant-tag {
  position: absolute;
  top: -44px;
  left: 40px;
}

.product-variants .ant-table-footer {
  padding: 15px 0;
}

.ant-layout-sider {
  background-color: #F6F8F9;
  border-bottom: 1px solid #e0e6ed;

  .ant-menu-light {
    background-color: #F6F8F9;
    border-right: 0 none;
  }
}
.ant-table-fixed{
  .ant-table-tbody{
    > tr > td{
      padding: 10px 15px;
    }
  }
}
.ant-layout-content {
  border-left: 1px solid #e0e6ed;
}

.ant-form.ant-form-vertical {
  .ant-row {
    margin-bottom: 10px;

    .ant-form-item-label {
      line-height: 25px;
    }

    label {
      font-weight: 500;
      padding-right: 15px;

      &:before {
        position: absolute;
        right: 0;
        top: 5px;
      }

      &:after {
        display: none;
      }
    }
  }
}

.ant-layout-header {
  height: 60px;
  line-height: 60px;
}

.pod-search-box input {
  font-size: 12px;
  background-color: rgba(81, 83, 101, 0.28);
  border: 1px solid rgba(81, 83, 101, 0.28);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #888ea8;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
}

@media screen and(max-width: 991px) {
  .ant-drawer-wrapper-body {
    .left-menu {
      height: 100vh !important;
      background: #FFF;
      padding-top: 0 !important;
    }
  }
}

.mapping-orders .ant-modal-body{
  padding-top:10px;
}

.gpt-input-search .ant-input-search-button{
  width: 150px;
  height: 37px;
  font-size: 17px;
  font-weight: 600;
}

.gpt-input-search input.ant-input {
  height: 37px;
}

.disabled-card{
  opacity: 0.6;
  pointer-events: none;
}